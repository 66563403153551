<template>
  <div v-if="$store.state.isDesktop" class="stop-wasting">
    <p>Clients become partners. We are better together.</p>
    <HorizontalScroll name="scroll-clients-1" :duration="30000" :reverse="true">
      <div class="partners">
        <SvgIcon name="blackball" />
        <SvgIcon name="boaboy" />
        <SvgIcon name="captainsboil" />
        <SvgIcon name="cocoru" />
        <SvgIcon name="ginger" />
        <SvgIcon name="haidilao-hotpot" />
      </div>
    </HorizontalScroll>
    <HorizontalScroll name="scroll-clients-2" :duration="30000">
      <div class="partners">
        <SvgIcon name="jimichi" />
        <SvgIcon name="kaori-izakaya" />
        <SvgIcon name="kokoro" />
        <SvgIcon name="ME-CREPE" />
        <SvgIcon name="miss-fu" />
        <SvgIcon name="sushi-shogun" />
        <SvgIcon name="tsukiji" />
      </div>
    </HorizontalScroll>
  </div>
</template>

<script>
import HorizontalScroll from '@/components/HorizontalScroll'
export default {
  name: 'ClientsPartners',
  components: { HorizontalScroll },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
