<template>
  <div class="fly-phone">
    <div class="anchor-text">
      <ul v-if="$store.state.isDesktop" ref="nav">
        <li v-for="(nav, index) in navList" :key="index" :class="active === index ? 'active' : ''">{{ nav }}</li>
      </ul>
      <b v-else class="title">MODERN , CONVENIENT , SMART , EFFECTIVE</b>
    </div>
    <div v-if="!$store.state.isDesktop" class="win-win-text">
      <b v-fadein="{ vertical: true }" class="title">B2B. B2C. Win-win.</b>
      <div v-fadein="{ vertical: true }" class="description">
        Feed your appetite for followers. Make the most of traffic flow. Through our exclusive system, you get to know the eating behavior of thousands of
        users. The analysis of relevant data about your subscribers helps you to conduct accurate marketing campaigns and boost the repurchase rate of your
        restaurant.
      </div>
    </div>
    <div v-if="!$store.state.isDesktop" class="phone-img">
      <img src="@/assets/images/single-flying-phone-1-mobile.png" alt="" />
    </div>
    <div v-if="$store.state.isDesktop" class="text-slider">
      <div class="title"><SvgIcon name="zomi" /></div>
      <div class="swiper-text">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in description" :key="index" class="swiper-slide swiper-slide1">
            <div class="description">{{ item }}</div>
          </div>
        </div>
        <div class="swiper-pagination" />
      </div>
    </div>
  </div>
</template>

<script>
// 插件
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'FlyPhone1',
  data() {
    return {
      active: 0,
      navList: ['MODERN', 'CONVENIENT', 'SMART', 'EFFECTIVE'],
      description: ['create your digital menu in minutes.', 'edit it anytime.', 'engage your followers on a daily basis.'],
    }
  },
  mounted() {
    const _this = this
    new Swiper('.swiper-text', {
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
    setInterval(function () {
      if (_this.active === _this.navList.length - 1) {
        _this.active = 0
      } else {
        _this.active++
      }
    }, 2000)
  },

  methods: {},
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
