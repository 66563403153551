<template>
  <div class="icon-plus-minus">
    <SvgIcon name="minus" />
    <SvgIcon name="minus" :class="active ? 'active' : ''" />
  </div>
</template>

<script>
export default {
  name: 'IconPlusMinus',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.icon-plus-minus {
  position: relative;
  stroke: var(--light-text-color);
  .icon-minus {
    width: 100%;
    height: 100%;

    &:last-child {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(90deg);
      transition: transform var(--transition-duration);

      &.active {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
