import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/NewHomeView'
import AboutView from '../views/AboutView'
import MenuView from '../views/MenuView'
import ContactView from '../views/ContactView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutView,
  },
  {
    path: '/menu-intellgence',
    name: 'menu-intellgence',
    component: MenuView,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
