<template>
  <WindowSection class="section-9 el">
    <template slot="content">
      <div v-fadein="{ vertical: true, type: 2 }" class="title">How online ordering works</div>
      <div v-fadein="{ vertical: true, type: 2 }" class="steps">
        <div class="step">
          <SvgIcon name="home-step-1" />
          <b>The customer places an order</b>
          <p>Customers order and pay ahead via your<br />website, social media, or QR code menus.</p>
        </div>
        <IconArrowRight :desktop-width="129" :mobile-width="129" color="#000" />
        <div class="step">
          <SvgIcon name="home-step-2" />
          <b>Restaurant accepts order</b>
          <p>Your restaurant accepts the order from any<br />IOS/Android device.</p>
        </div>
        <IconArrowRight :desktop-width="129" :mobile-width="129" color="#000" />
        <div class="step">
          <SvgIcon name="home-step-3" />
          <b>The Order is ready to be picked up</b>
          <p>The customer or delivery driver picks up<br />the order when notified.</p>
        </div>
      </div>
    </template>
  </WindowSection>
</template>

<script>
import IconArrowRight from '@/components/IconArrowRight'
import WindowSection from '../WindowSection'
export default {
  name: 'SectionNineth',
  components: { WindowSection, IconArrowRight },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
