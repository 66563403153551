<template>
  <div class="contact-view">
    <div class="content">
      <!-- Calendly inline widget begin -->
      <div class="calendly-inline-widget" data-url="https://calendly.com/hello-hgy/introductory-call" />
      <!-- Calendly inline widget end -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  data() {
    return {}
  },
  mounted() {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    document.head.appendChild(script)
  },
  methods: {},
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
