<template>
  <div class="wrap">
    <template v-if="$store.state.isDesktop">
      <div class="content-wrap">
        <button class="coming-btn swiper-fadein fadein vertical" data-active="4">COMING SOON</button>
        <div class="content swiper-fadein fadein vertical" data-active="4" data-delay="1000">
          <ul>
            <li v-for="(item, index) in content" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="svg-wrap"><SvgIcon name="coming-soon" /></div>
    </template>

    <div v-else class="content-wrap">
      <div class="svg-wrap"><SvgIcon name="coming-soon" /></div>
      <button v-fadein="{ vertical: true }" class="coming-btn">COMING SOON</button>
      <div v-fadein="{ delay: 1000, vertical: true }" class="content">
        <ul>
          <li v-for="(item, index) in content" :key="index">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
      content: [
        '• Tailored SMS: customize messages and reach your followers anytime.',
        '• Campaign planning: notify all your followers after you publish a campaign.',
        '• New item release: automatically inform your loyal clients when you release a new item.',
        '• Win-back followers: send a friendly reminder to your followers who are past their average frequency.',
        '• Feedback collection: obtain relevant feedback from your followers.',
      ],
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
