<template>
  <div class="home-view">
    <template v-if="$store.state.isDesktop">
      <SectionSecond />

      <SectionSixth />

      <SectionNineth />
    </template>

    <div :class="{ 'scroll-container': $store.state.isDesktop }">
      <div class="demo-container">
        <div class="demo pointer" @click="jump">TALK TO US NOW</div>
      </div>
      <!-- Section 1 -->
      <BasicSection class="section-1">
        <div slot="left" class="left" style="width: calc(100% - 800px)">
          <div v-fadein="{ firstPage: true, vertical: true }" class="text">
            Why give up <i>20-30%</i> of your<br />
            revenue when you can keep <i>100%</i><br />
            by building direct relationships<br />
            with your customers?<br />
          </div>

          <button>
            <IconArrowRight :desktop-width="48" :mobile-width="68" />
            <span @click="jump">Get Started</span>
          </button>
        </div>
        <div slot="right" class="right" style="width: 800px">
          <img
            v-if="$store.state.isDesktop"
            v-fadein="{ firstPage: true, horizontal: true }"
            src="./assets/images/section-1-desktop.png"
            style="width: 800px"
          />
          <img v-else v-fadein="{ firstPage: true, vertical: true }" src="./assets/images/section-1-mobile.png" style="width: 362px" />
        </div>
      </BasicSection>

      <!-- Section 2 -->
      <div v-if="$store.state.isDesktop" id="section-2" class="section-2-placeholder placeholder" />
      <SectionSecond v-else />

      <!-- Section 3 -->
      <BasicSection class="section-3">
        <div slot="left" class="left" style="width: 50%">
          <img v-if="$store.state.isDesktop" v-fadein="{ horizontal: true, reserve: true }" src="./assets/images/section-3-desktop.png" style="width: 550px" />
          <img v-else v-fadein="{ vertical: true }" src="./assets/images/section-3-desktop.png" style="width: 338px" />
        </div>
        <div slot="right" class="right" style="width: 50%">
          <div v-fadein="{ vertical: true }" class="text">
            <div class="title">
              <i>Own</i> <em>your<br />customers</em>
            </div>
            Third-party platforms control all of your customers.<br />
            Even if customers order from you daily, you will be<br />
            charged 20-30% in commissions
          </div>
        </div>
      </BasicSection>

      <!-- Section 4 -->
      <BasicSection class="section-4" reserve>
        <div slot="left" class="left" style="width: 50%">
          <div v-fadein="{ vertical: true }" class="text">
            <div class="title">Pay <i>0%</i><br />commission</div>
            Building direct relationships with your customers<br />and keeping <i>100%</i> of your sales
          </div>
        </div>
        <div slot="right" class="right" style="width: 50%">
          <img v-if="$store.state.isDesktop" v-fadein="{ horizontal: true }" src="./assets/images/section-4-desktop.png" style="width: 564px" />
          <img v-else v-fadein="{ vertical: true }" src="./assets/images/section-4-desktop.png" style="width: 338px" />
        </div>
      </BasicSection>

      <!-- Section 5 -->
      <BasicSection class="section-5">
        <div slot="left" class="left" style="width: 50%">
          <img v-if="$store.state.isDesktop" v-fadein="{ horizontal: true, reserve: true }" src="./assets/images/section-5-desktop.png" style="width: 564px" />
          <img v-else v-fadein="{ vertical: true }" src="./assets/images/section-5-desktop.png" style="width: 338px" />
        </div>
        <div slot="right" class="right" style="width: 50%">
          <div v-fadein="{ vertical: true }" class="text">
            <div class="title">
              <i>Exponential<br />growth</i> in<br />online order<br />volume
            </div>
            The more customers you digitize,<br />
            the more revenue you will generate.
          </div>
        </div>
      </BasicSection>

      <!-- Section 6 -->
      <div v-if="$store.state.isDesktop" id="section-6" class="section-6-placeholder placeholder" />
      <SectionSixth v-else />

      <!-- Section 7 -->
      <BasicSection class="section-7" reserve>
        <div slot="left" class="left" style="width: 50%">
          <div v-fadein="{ vertical: true }" class="text">
            <div class="title">Awarded Customer&nbsp;<br v-if="!$store.state.isDesktop" />Experience</div>
            <div class="line">
              <SvgIcon name="checked" />
              <p v-if="$store.state.isDesktop">
                The visual and experiential aspects of the<br />ordering process have a significant impact on the<br />conversion rate.
              </p>
              <p v-else>The visual and experiential aspects<br />of the ordering process have a<br />significant impact on the<br />conversion rate.</p>
            </div>
            <div class="line">
              <SvgIcon name="checked" />
              <p v-if="$store.state.isDesktop">We provide one of the best user experiences in<br />the market, empowering your brand.</p>
              <p v-else>We provide one of the best user<br />experiences in the market,<br />empowering your brand.</p>
            </div>
          </div>
        </div>
        <div slot="right" class="right" style="width: 50%">
          <img v-if="$store.state.isDesktop" v-fadein="{ horizontal: true }" src="./assets/images/section-7-desktop.png" style="width: 525px" />
          <img v-else v-fadein="{ vertical: true }" src="./assets/images/section-7-desktop.png" style="width: 300px" />
        </div>
      </BasicSection>

      <!-- Section 8 -->
      <BasicSection class="section-8" reserve>
        <div slot="left" class="left" style="width: 50%">
          <div v-fadein="{ vertical: true }" class="text">
            <div v-if="$store.state.isDesktop" class="title">
              Turn traffic from all sources<br />
              into followers automatically<br />+ Operational Support
            </div>
            <div v-else class="title">
              Turn traffic from all sources into<br />
              followers automatically<br />+ Operational Support
            </div>
            <div class="line">
              <SvgIcon name="checked" />
              <p v-if="$store.state.isDesktop">Every guest that visits your menu will be digitalized and<br />have a great chance of becoming your follower.</p>
              <p v-else>Every guest that visits your menu<br />will be digitalized and have a great<br />chance of becoming your follower.</p>
            </div>
            <div class="line">
              <SvgIcon name="checked" />
              <p v-if="$store.state.isDesktop">Leave everything to us. We will automate the entire<br />process, hassle-free</p>
              <p v-else>Leave everything to us. We will<br />automate the entire process,<br />hassle-free</p>
            </div>
          </div>
        </div>
        <div slot="right" class="right" style="width: 50%">
          <img v-if="$store.state.isDesktop" v-fadein="{ horizontal: true }" src="./assets/images/section-8-desktop.png" style="width: 525px" />
          <img v-else v-fadein="{ vertical: true }" src="./assets/images/section-8-desktop.png" style="width: 300px" />
        </div>
      </BasicSection>

      <!-- Section 9 -->
      <div v-if="$store.state.isDesktop" id="section-9" class="section-9-placeholder placeholder" />
      <SectionNineth v-else />

      <PriceSection />
      <ClientsPartners />
      <GlobalFooter />
    </div>
  </div>
</template>

<script>
import IconArrowRight from '@/components/IconArrowRight'

import BasicSection from './components/BasicSection'
import SectionSecond from './components/SectionSecond'
import SectionSixth from './components/SectionSixth'
import SectionNineth from './components/SectionNineth'

import PriceSection from './components/PriceSection'
import ClientsPartners from './components/ClientsPartners'

import GlobalFooter from '@/components/GlobalFooter'
export default {
  name: 'NewHomeView',
  components: { BasicSection, SectionSecond, SectionSixth, SectionNineth, GlobalFooter, IconArrowRight, PriceSection, ClientsPartners },
  mounted() {
    if (this.$store.state.isDesktop) document.querySelector('.scroll-container').addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const _this = this
      const sectionWindows = document.querySelectorAll('.placeholder')

      document.querySelectorAll('.el').forEach((e) => {
        e.style.opacity = 0
      })

      for (let sectionWindow of sectionWindows) {
        const inView = _this.isInViewPort(sectionWindow)
        if (inView) {
          const id = sectionWindow.getAttribute('id')
          const el = document.querySelector('.' + id)
          el.style.opacity = 1
          break
        }
      }
    },
    isInViewPort(el) {
      const { height } = document.querySelector('.scroll-container').getBoundingClientRect()
      const { top: elTop } = el.getBoundingClientRect()
      return elTop > -540 && elTop < height
    },
    jump() {
      this.$router.push({
        name: 'contact-us',
      })
      if (!this.$store.state.isDesktop) window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
