<template>
  <div class="fly-phone">
    <b v-if="$store.state.isDesktop" v-fadein="{ vertical: true }" class="win-title">B2B. B2C. Win-win.</b>
    <div v-if="$store.state.isDesktop" v-fadein="{ vertical: true }" class="win-description">
      Feed your appetite for followers. Make the most of traffic flow. Through our exclusive system, you get to know the eating behavior of thousands of users.
      The analysis of relevant data about your subscribers helps you to conduct accurate marketing campaigns and boost the repurchase rate of your restaurant.
    </div>
    <b v-fadein="{ vertical: true }" class="market-title">market research for accurate results.</b>
    <div v-fadein="{ vertical: true }" class="market-description">
      To develop MENU Intelligence – Powered by ZOMI, we listened to over 80 restaurant owners and managers in Metro Vancouver. This is why our system is smart,
      easy to use, and suitable for your needs. Also, our customer support team is ready to offer high-quality assistance every time you need it.
    </div>
    <div v-if="!$store.state.isDesktop" class="phone-img">
      <img src="@/assets/images/single-flying-phone-2-mobile.png" alt="" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'FlyPhone2',
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
