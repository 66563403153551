<template>
  <div class="window-section">
    <div class="box">
      <div class="content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WindowSection',
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
