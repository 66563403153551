<template>
  <div class="video-banner">
    <video
      v-if="$store.state.isDesktop"
      muted
      autoplay
      loop
      playsinline
      webkit-playsinline
      src="https://player.vimeo.com/progressive_redirect/playback/722342350/rendition/720p/file.mp4?loc=external&signature=3b5367a58e943f5b03cb36221cb158a9e0d67089ab7b3ac4b7b53657afc02b4a"
    />
    <video
      v-else
      muted
      autoplay
      loop
      playsinline
      webkit-playsinline
      src="https://player.vimeo.com/progressive_redirect/playback/722342357/rendition/720p/file.mp4?loc=external&signature=6ccc69c38c8edf6d40b44f35e2c5c5d4775f87c19a59a29b3d5f72e7ab90809b"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  components: {},
  data() {
    return {
      timer: null,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
