<template>
  <div ref="wrap" class="wrap">
    <div class="swiper-digital scroll-dark">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in MenuPower" :key="index" class="swiper-slide">
          <div class="content-wrap">
            <div class="title" v-html="item.title" />
            <div v-if="!$store.state.isDesktop" class="svg-wrap">
              <SvgIcon :name="item.iconName" />
            </div>
            <div v-if="$store.state.isDesktop" class="desc">
              <div class="open-btn pointer" @click="toggleContent(index)">
                <IconPlusMinus :active="item.isOpen" />
              </div>
              <transition name="display">
                <div v-if="item.isOpen" class="desc-wrap">
                  <p v-for="(itemSub, indexSub) in item.desc" :key="indexSub" class="desc-content">{{ itemSub }}</p>
                </div>
              </transition>
            </div>
            <div v-else class="desc">
              <div class="desc-wrap">
                <p v-for="(itemSub, indexSub) in item.desc" :key="indexSub" class="desc-content">{{ itemSub }}</p>
              </div>
            </div>
          </div>
          <div v-if="$store.state.isDesktop" class="svg-wrap">
            <SvgIcon :name="item.iconName" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlusMinus from '@/components/IconPlusMinus'
// 插件
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'DigitalSlider',
  components: { IconPlusMinus },
  data() {
    return {
      digitalSliderSwiper: null,
      MenuPower: [
        {
          title: 'Digital expertise.<br />Authentic relationships.',
          desc: [
            '• Valuable guest data: make use of everything you know about your followers to build a 1:1 relationship with them.',
            '• Reward program: create a reward program for your returning customers and boost the repurchase rate of your business.',
            '• Customer satisfaction: analyze the comportment of your guests to develop exclusive marketing campaigns.',
          ],
          iconName: 'digital-1',
          isOpen: false,
        },
        {
          title: 'Innovation + commitment: <br />a continuous practice.',
          desc: [
            'ZOMI is truly committed to your brand. We will constantly provide your restaurant and your clients with an up-to-date menu, improving features and user navigation. Plus, the standard version of MENU Intelligence – Powered by ZOMI will always be free of charge.',
          ],
          iconName: 'digital-2',
          isOpen: false,
        },
      ],
      scrollActive: false,
    }
  },
  mounted() {
    if (this.$store.state.isDesktop) {
      this.$refs['wrap'].addEventListener('mousewheel', this.handleMousewheelScroll, false)

      this.digitalSliderSwiper = new Swiper('.swiper-digital', {
        speed: 1000,
        autoplay: false,
      })
    }
  },
  beforeDestroy() {
    if (this.$store.state.isDesktop) {
      this.$refs['wrap'].removeEventListener('mousewheel', this.handleMousewheelScroll, false)
    }
  },
  methods: {
    toggleContent(index) {
      this.MenuPower[index].isOpen = !this.MenuPower[index].isOpen
    },
    handleMousewheelScroll(e) {
      const _this = this
      if (_this.scrollActive) return false
      if (e.wheelDelta > 60) {
        if (_this.digitalSliderSwiper.activeIndex === 0) {
          _this.$emit('isDigitalSliderSwiperFirst', true)
          _this.scrolling()
        } else {
          _this.digitalSliderSwiper.slidePrev()
          _this.$emit('isDigitalSliderSwiperFirst', false)
          _this.scrolling()
        }
      } else if (e.wheelDelta < -60) {
        if (_this.digitalSliderSwiper.activeIndex === _this.digitalSliderSwiper.slides.length - 1) {
          _this.$emit('isDigitalSliderSwiperLast', true)
          _this.scrolling()
        } else {
          _this.digitalSliderSwiper.slideNext()
          _this.$emit('isDigitalSliderSwiperLast', false)
          _this.scrolling()
        }
      }
    },
    scrolling() {
      const _this = this
      _this.scrollActive = true
      setTimeout(function () {
        _this.scrollActive = false
      }, 1500)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
