<template>
  <div class="wrap">
    <div v-if="$store.state.isDesktop" class="slider-wrap" @click="toggleContent()">
      <p v-if="isShow">
        ZOMI is a vancouver-based <SvgIcon name="startup" /> with a dynamic <SvgIcon name="proposal" />. While connecting <SvgIcon name="store" /> and foodies,
        we digitalize every <SvgIcon name="order" />, <SvgIcon name="lost" /> taste preferences, and provide customers with an innovative way of enjoying
        <SvgIcon name="group" /> experiences.
      </p>
      <p v-else>
        ZOMI is a vancouver-based start-up with a dynamic proposal. While connecting restaurants and foodies, we digitalize every dish, map taste preferences,
        and provide customers with an innovative way of enjoying food experiences.
      </p>
    </div>
    <div v-else class="slider-wrap">
      <p>
        ZOMI is a vancouver-based start-up with a dynamic proposal. While connecting restaurants and foodies, we digitalize every dish, map taste preferences,
        and provide customers with an innovative way of enjoying food experiences.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextSlider',
  data() {
    return { isShow: true }
  },
  mounted() {},
  methods: {
    toggleContent() {
      this.isShow = !this.isShow
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
