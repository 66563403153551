import Vue from 'vue'

export default class Fadein {
  constructor() {
    this.list = []
    this.hasBindScroll = false
    this.scrollActive = false
    this.parent = null
  }

  add(el, binding) {
    const _this = this
    Vue.nextTick(() => {
      if (binding.value && binding.value.type) {
        // only desktop
        if (binding.value.type === 1 && window.innerWidth <= 768) {
          return false
        }
        // only mobile
        if (binding.value.type === 2 && window.innerWidth > 768) {
          return false
        }
      }
      el.classList.add('fadein')
      if (binding.value && binding.value.horizontal) {
        el.classList.add('horizontal')
      }
      if (binding.value && binding.value.vertical) {
        el.classList.add('vertical')
      }
      if (binding.value && binding.value.reserve) {
        el.classList.add('reserve')
      }
      if (binding.value && binding.value.firstPage) {
        setTimeout(function () {
          el.classList.add('show')
        }, binding.value.delay || 500)
      }
      _this.list.push({ el, binding })
      if (!_this.hasBindScroll) {
        if (window.innerWidth > 768) {
          _this.parent = document.querySelector('.scroll-container')
        } else {
          _this.parent = window
        }
        _this.parent.addEventListener('scroll', _this.handleScroll.bind(this))

        _this.hasBindScroll = true
      }
    })
  }

  handleScroll() {
    const _this = this
    if (_this.scrollActive) return false
    _this.scrollActive = true
    _this.list.forEach((item) => {
      if (_this.isInViewPort(item.el)) {
        if (item.binding.value) {
          setTimeout(function () {
            item.el.classList.add('show')
          }, item.binding.value.delay)
        } else {
          item.el.classList.add('show')
        }
      }
    })
    setTimeout(function () {
      _this.scrollActive = false
    }, 100)
  }

  isInViewPort(el) {
    if (window.innerWidth > 768) {
      const { top, height } = this.parent.getBoundingClientRect()
      const { top: elTop } = el.getBoundingClientRect()
      return elTop - height < top
    } else {
      const top = 0
      const height = window.innerHeight
      const { top: elTop } = el.getBoundingClientRect()
      return elTop - height < top
    }
  }

  destroy() {
    this.parent.removeEventListener('scroll', this.handleScroll)
    this.list = []
    this.hasBindScroll = false
    this.scrollActive = false
  }
}
