var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-footer"},[(_vm.$store.state.isDesktop)?_c('div',{staticClass:"footer-desktop"},[_c('ul',{staticClass:"footer-left"},[_c('li',{staticClass:"call-us"},[_c('IconArrowRight',{attrs:{"desktop-width":118}}),_vm._m(0)],1),_c('li',{staticClass:"email"},[_c('IconArrowRight',{attrs:{"desktop-width":195}}),_vm._m(1)],1),_vm._m(2)]),_c('div',{staticClass:"footer-center"},[_c('div',{staticClass:"zomi-ins"},[_c('SvgIcon',{attrs:{"name":"zomi-footer"}}),_c('a',{attrs:{"href":"https://www.instagram.com/zomi_experience/","target":"_blank"}},[_c('SvgIcon',{attrs:{"name":"ins"}})],1)],1),_c('div',{staticClass:"hours"},[_vm._m(3),_c('SvgIcon',{attrs:{"name":"office-hours"}})],1)]),_c('div',{staticClass:"footer-right"},[_c('div',[_c('div',{staticClass:"qrcode-title"},[_vm._m(4),_c('SvgIcon',{attrs:{"name":"phone"}})],1),_vm._m(5)])])]):_c('div',{staticClass:"footer-mobile"},[_c('div',{staticClass:"zomi-ins"},[_c('SvgIcon',{attrs:{"name":"zomi-footer"}}),_c('a',{attrs:{"href":"https://www.instagram.com/zomi_experience/","target":"_blank"}},[_c('SvgIcon',{attrs:{"name":"ins"}})],1)],1),_c('div',{staticClass:"find-us"},[_vm._m(6),_c('SvgIcon',{attrs:{"name":"face-1"}})],1),_c('div',{staticClass:"email"},[_c('IconArrowRight',{attrs:{"mobile-width":230}}),_c('span',[_vm._v("HELLO@ZOMI.MENU")])],1),_c('div',{staticClass:"call-us"},[_c('IconArrowRight',{attrs:{"mobile-width":140}}),_vm._m(7)],1),_c('div',{staticClass:"qrcode-and-hours"},[_c('div',{staticClass:"qrcode"},[_c('div',[_c('div',[_vm._m(8),_c('SvgIcon',{attrs:{"name":"phone"}})],1),_vm._m(9)])]),_c('div',{staticClass:"hours"},[_c('SvgIcon',{attrs:{"name":"office-hours-mobile"}}),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"tel:7783174160"}},[_c('span',[_vm._v("CALL US AT: 778-317-4160")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"mailto:HELLO@ZOMI.MENU"}},[_c('span',[_vm._v("HELLO@ZOMI.MENU")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"find-us"},[_c('span',[_vm._v("FIND US:")]),_c('div',[_vm._v(" 450 SW Marine Dr Floor 18"),_c('br'),_vm._v(" VANCOUVER, BC"),_c('br'),_vm._v(" V5X 0C3 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Monday to Friday:"),_c('br'),_vm._v(" 9:00 a.m. to 9:00 p.m. (PT) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("SCAN FOR")]),_c('div',[_vm._v("WHATSAPP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://wa.me/7788958661"}},[_c('img',{attrs:{"src":require("@/assets/images/QRCode.jpeg"),"draggable":"false"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" FIND US AT:"),_c('br'),_vm._v(" 450 SW Marine Dr Floor 18"),_c('br'),_vm._v(" VANCOUVER, BC"),_c('br'),_vm._v(" V5X 0C3 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"tel:7783174160"}},[_c('span',[_vm._v("CALL US AT: 778-317-4160")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" SCAN FOR"),_c('br'),_vm._v(" WHATSAPP ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://wa.me/7788958661"}},[_c('img',{attrs:{"src":require("@/assets/images/QRCode.jpeg"),"draggable":"false"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Monday to Friday:"),_c('br'),_vm._v(" 9:00 a.m. to 9:00 p.m. (PT) ")])
}]

export { render, staticRenderFns }