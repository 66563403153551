<template>
  <div
    class="horizontal-scroll"
    :style="{ '--duration': duration / 1000 + 's', '--delay': duration / 2000 + 's', '--reverse': reverse ? 'reverse' : 'normal' }"
  >
    <div class="animate-container">
      <slot />
    </div>
    <div class="animate-container copy">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalScroll',
  props: {
    duration: {
      type: Number,
      default: 10000,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style>
@keyframes horizontalScroll {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes horizontalScrollCopy {
  0% {
    transform: translate(-100%, -100%);
  }

  100% {
    transform: translate(100%, -100%);
  }
}
.animate-container:not(.copy) {
  animation: horizontalScroll var(--duration) linear 0s infinite var(--reverse);
}

.animate-container.copy {
  animation: horizontalScrollCopy var(--duration) linear var(--delay) infinite var(--reverse);
}
</style>
