<template>
  <div ref="menu-view" class="menu-view">
    <div class="demo-container">
      <div class="demo pointer" @click="slideToDownload">BUILD YOUR MENU NOW</div>
    </div>
    <div v-if="$store.state.isDesktop" class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-menu">
          <ImageBanner />
        </div>
        <div class="swiper-slide swiper-slide-menu">
          <PhoneSlider
            ref="phone-slider"
            @isPhoneSliderSwiperFirst="getPhoneSliderFirst"
            @isPhoneSliderSwiperLast="getPhoneSliderLast"
            @handleContactClick="handleContactClick"
          />
        </div>
        <div class="swiper-slide swiper-slide-menu">
          <DigitalSlider @isDigitalSliderSwiperFirst="getDigitalSliderFirst" @isDigitalSliderSwiperLast="getDigitalSliderLast" />
        </div>
        <div class="swiper-slide swiper-slide-menu">
          <NewContact @handleUpgradeClick="handleUpgradeClick" />
        </div>
        <div class="swiper-slide swiper-slide-menu">
          <ComingSoon />
        </div>
        <div ref="scroll-container" class="swiper-slide scroll-container">
          <FaqForm />
          <GlobalFooter />
        </div>
      </div>
    </div>
    <div v-if="!$store.state.isDesktop" ref="scroll-container" class="phone-wrap">
      <ImageBanner />
      <PhoneSlider ref="download-container" @handleContactClick="handleContactClick" />
      <DigitalSlider />
      <NewContact @handleUpgradeClick="handleUpgradeClick" />
      <ComingSoon />
      <FaqForm ref="form-container" />
      <GlobalFooter />
    </div>
  </div>
</template>

<script>
// 插件
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

// 组件
import ImageBanner from './components/ImageBanner'
import PhoneSlider from './components/PhoneSlider'
import DigitalSlider from './components/DigitalSlider'
import ComingSoon from './components/ComingSoon'
import NewContact from './components/NewContact'
import FaqForm from '@/views/HomeView/components/FaqForm'

// 全局组件
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'MenuView',
  components: { ImageBanner, PhoneSlider, DigitalSlider, ComingSoon, NewContact, FaqForm, GlobalFooter },
  data() {
    return {
      scrollActive: false,
      mainSwiper: null,
      isPhoneSliderEnd: false,
      isDigitalSliderEnd: false,
      endWait: false,
      isPhoneSliderBeginning: true,
      isDigitalSliderBeginning: true,
      beginningWait: false,

      isPhoneSliderSwiperFirst: false,
      isPhoneSliderSwiperLast: false,
      currentSlider: 0,
    }
  },
  mounted() {
    const _this = this
    if (this.$store.state.isDesktop) {
      window.addEventListener('mousewheel', _this.handleMousewheelScroll, false)

      // Swiper
      _this.mainSwiper = new Swiper('.swiper-container', {
        speed: 1000,
        direction: 'vertical',
        allowTouchMove: false,
        on: {
          slideChangeTransitionEnd: function () {
            let direction = true
            if (this.activeIndex < _this.currentSlider) direction = false
            _this.currentSlider = this.activeIndex
            document.querySelectorAll('.swiper-fadein').forEach((item) => {
              const active = item.getAttribute('data-active') * 1
              if (direction && this.index !== 1) {
                if (active === this.activeIndex) {
                  const delay = item.getAttribute('data-delay') * 1
                  if (delay) {
                    setTimeout(function () {
                      item.classList.add('show')
                    }, delay)
                  } else {
                    item.classList.add('show')
                  }
                }
              }
            })
          },
        },
      })
    }
    if (this.$route.params.download) {
      _this.slideToDownload()
    }
  },
  beforeDestroy() {
    if (this.$store.state.isDesktop) {
      window.removeEventListener('mousewheel', this.handleMousewheelScroll, false)
    }
  },
  methods: {
    // contact 点击事件
    handleContactClick() {
      this.slideToForm()
    },
    // upgrade 点击事件
    handleUpgradeClick() {
      this.slideToForm()
    },
    // demo 点击事件
    handleDemoClick() {
      this.slideToForm()
    },
    slideToDownload() {
      const _this = this
      if (_this.$store.state.isDesktop) {
        _this.mainSwiper.slideTo(1)
        _this.$refs['phone-slider'].phoneSliderSwiper.slideTo(0)
      } else {
        const formContainer = _this.$refs['download-container']
        formContainer.openDownload()
        // 计算表单到顶部的距离
        const distance = formContainer.$el.offsetTop
        _this.scrollToAnimationFn(distance - 100, 300)
      }
    },
    slideToForm() {
      const _this = this
      // 电脑端
      if (_this.$store.state.isDesktop) {
        _this.mainSwiper.slideTo(5)
      } else {
        const formContainer = _this.$refs['form-container'].$el.querySelector('.form-box')
        // 计算表单到顶部的距离
        const distance = formContainer.offsetTop
        _this.scrollToAnimationFn(distance + 140, 300)
      }
    },
    scrollToAnimationFn(number = 0, time) {
      const _this = this
      if (!time) {
        document.body.scrollTop = document.documentElement.scrollTop = number
        return number
      }
      const spacingTime = 20
      let spacingInex = time / spacingTime
      let nowTop = document.body.scrollTop || document.documentElement.scrollTop
      const everTop = (number - nowTop) / spacingInex
      const scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--
          _this.scrollToAnimationFn((nowTop += everTop))
        } else {
          clearInterval(scrollTimer)
        }
      }, spacingTime)
    },
    // 滚轮事件
    handleMousewheelScroll(e) {
      const _this = this
      if (_this.scrollActive) return false
      // 电脑端多页切换
      switch (_this.mainSwiper.activeIndex) {
        case 1:
          if (e.wheelDelta > 60) {
            if (_this.isPhoneSliderSwiperFirst) {
              _this.isPhoneSliderSwiperFirst = false
              _this.mainSwiper.slidePrev()
              _this.scrolling()
            }
          } else if (e.wheelDelta < -60) {
            if (_this.isPhoneSliderSwiperLast) {
              _this.isPhoneSliderSwiperLast = false
              _this.mainSwiper.slideNext()
              _this.scrolling()
            }
          }
          break
        case 2:
          if (e.wheelDelta > 60) {
            if (_this.isDigitalSliderSwiperFirst) {
              _this.isDigitalSliderSwiperFirst = false
              _this.mainSwiper.slidePrev()
              _this.scrolling()
            }
          } else if (e.wheelDelta < -60) {
            if (_this.isDigitalSliderSwiperLast) {
              _this.isDigitalSliderSwiperLast = false
              _this.mainSwiper.slideNext()
              _this.scrolling()
            }
          }
          break
        case 5: {
          const scrollTop = _this.$refs['scroll-container'].scrollTop
          // 如果滚轮是向上，并且在顶部
          if (e.wheelDelta > 60 && !scrollTop) {
            _this.mainSwiper.slidePrev()
            _this.scrolling()
          }
          this.clearSlide()
          break
        }
        default:
          if (e.wheelDelta > 60) {
            _this.mainSwiper.slidePrev()
            _this.scrolling()
          } else if (e.wheelDelta < -60) {
            _this.mainSwiper.slideNext()
            _this.scrolling()
          }
          this.clearSlide()
          break
      }
    },
    scrolling() {
      const _this = this
      _this.scrollActive = true
      setTimeout(function () {
        _this.scrollActive = false
      }, 1500)
    },
    // swiper竖页切换事件 next
    handlePageNext() {
      this.beginningWait = false
      if (this.endWait) {
        this.mainSwiper.slideNext()
        this.endWait = false
      } else {
        this.endWait = true
      }
    },
    // swiper竖页切换事件 prev
    handlePagePrev() {
      this.endWait = false
      if (this.beginningWait) {
        this.mainSwiper.slidePrev()
        this.beginningWait = false
      } else {
        this.beginningWait = true
      }
    },
    getPhoneSliderFirst(val) {
      this.isPhoneSliderSwiperFirst = val
    },
    getPhoneSliderLast(val) {
      this.isPhoneSliderSwiperLast = val
    },
    getDigitalSliderFirst(val) {
      this.isDigitalSliderSwiperFirst = val
    },
    getDigitalSliderLast(val) {
      this.isDigitalSliderSwiperLast = val
    },
    clearSlide() {
      this.isPhoneSliderSwiperFirst = false
      this.isPhoneSliderSwiperLast = false
      this.isDigitalSliderSwiperFirst = false
      this.isDigitalSliderSwiperLast = false
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
