<template>
  <div class="image-banner">
    <img v-fadein="{ firstPage: true, vertical: true }" src="@/assets/images/menu-banner.png" />
    <div v-if="$store.state.isDesktop" v-fadein="{ firstPage: true, delay: 1000, vertical: true }" class="banner-content">
      <h1>MENU INTELLIGENCE</h1>
      <p>POWERED BY ZOMI</p>
      <p>Set up your own digital menu for free.<br />It is practical and quick.</p>
    </div>
    <div v-else v-fadein="{ firstPage: true, delay: 1000, vertical: true }" class="banner-content">
      <h1>MENU INTELLIGENCE <br />- POWERED BY ZOMI</h1>
      <p>Set up your own digital menu for free.<br />It is practical and quick.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBanner',
  components: {},
  data() {
    return {
      timer: null,
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
