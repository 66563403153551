<template>
  <div class="wrap">
    <div v-if="$store.state.isDesktop" class="content-wrap">
      <button class="new-btn">new</button>
      <div class="title swiper-fadein fadein vertical" data-active="3">
        <div class="title-1">MENU intelligence<br />– Powered by ZOMI:</div>
        <div class="title-2">ONBOARDING PACKAGE.</div>
        <div class="title-3">No contract, no worries.</div>
      </div>
      <div class="content swiper-fadein fadein vertical" data-active="3" data-delay="1000">
        <div class="content-1">Upgrade your plan to:</div>
        <div class="content-2">
          • Receive modern QR stickers with NFC by mail (your clients can simply tap their phones to access your menu).<br />• Leave the set-up of your menu
          entirely to our team.<br />• Count on the best post-sale support (24/7).
        </div>
      </div>
    </div>

    <div v-else class="content-wrap">
      <button class="new-btn">new</button>
      <div v-fadein="{ vertical: true }" class="title">
        <div class="title-1">MENU intelligence<br />– Powered by ZOMI:</div>
        <div class="title-2">ONBOARDING PACKAGE.</div>
        <div class="title-3">No contract, no worries.</div>
      </div>
      <div v-fadein="{ delay: 1000, vertical: true }" class="content">
        <div class="content-1">Upgrade your plan and also receive:</div>
        <div class="content-2">
          • Complete installation of the app.<br />• Upload of images, names, and descriptions. <br />• The best post-sale support (24/7), with specialists
          always online.
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <button class="info-btn" @click="handleUpgradeClick">upgrade your plan</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewContact',
  methods: {
    handleUpgradeClick() {
      this.$emit('handleUpgradeClick')
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
