<template>
  <div ref="wrap" class="wrap">
    <b v-if="$store.state.isDesktop" ref="follow-text" class="title swiper-fadein fadein vertical" data-active="1"
      >Follow a few simple<br />steps to grow your<br />business:</b
    >
    <b v-else v-fadein="{ firstPage: true, delay: 2000, vertical: true }" class="title">Follow a few simple steps<br />to grow your business:</b>
    <div v-if="$store.state.isDesktop" class="swiper-menu-power scroll">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in MenuPower" :key="index" class="swiper-slide">
          <div class="box">
            <img :src="item.imgSrc" alt="" />
            <a v-if="!index" href="https://play.google.com/store/apps/details?id=com.zomi.mymenu" class="google-play" target="_blank" />
            <a
              v-if="!index"
              href="https://apps.apple.com/us/app/zomi-my-business/id1531032927?itsct=apps_box_link&itscg=30200"
              class="app-store"
              target="_blank"
            />
          </div>
          <div class="content-wrap">
            <div class="icon-wrap"><SvgIcon :name="item.icon" /></div>
            <div class="content">
              <span class="num">{{ item.num }}</span>
              <span class="desc">{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="toggle-wrap">
      <div v-for="(item, index) in MenuPowerMobile" :key="index" class="toggle-item pointer">
        <button class="open-btn" @click="toggle(index)">{{ item.desc }}</button>
        <transition name="display">
          <div v-show="item.isOpen" class="img-wrap">
            <div class="box">
              <img :src="item.imgSrc" alt="" />
              <a v-if="!index" href="#" class="google-play" target="_blank" />
              <a
                v-if="!index"
                href="https://apps.apple.com/us/app/zomi-my-business/id1531032927?itsct=apps_box_link&itscg=30200"
                class="app-store"
                target="_blank"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// 插件
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'PhoneSlider',
  components: {},
  data() {
    return {
      phoneSliderSwiper: null,
      isShow: false,
      MenuPower: [
        {
          imgSrc: require('@/assets/images/menu-swiper-phone-5.png'),
          icon: 'download',
          num: 1,
          desc: 'Download the app on your smartphone.',
        },
        {
          imgSrc: require('@/assets/images/menu-swiper-phone-1.png'),
          icon: 'dish-list',
          num: 2,
          desc: 'Build your own digital menu, using all the tools we offer to make it unique.',
        },
        {
          imgSrc: require('@/assets/images/menu-swiper-phone-2.png'),
          icon: 'planet',
          num: 3,
          desc: 'Define a welcome offer and turn your dine-in customers into your followers.',
        },
      ],
      MenuPowerMobile: [
        { imgSrc: require('@/assets/images/menu-swiper-phone-5.png'), desc: '1. Download the app', isOpen: false },
        { imgSrc: require('@/assets/images/menu-swiper-phone-1.png'), desc: '2. CREATE YOUR MENU', isOpen: false },
        { imgSrc: require('@/assets/images/menu-swiper-phone-2.png'), desc: '3. Reward your followers', isOpen: false },
      ],
      lastSlide: false,
      scrollActive: false,
    }
  },
  mounted() {
    const _this = this
    if (_this.$store.state.isDesktop) {
      _this.$refs['wrap'].addEventListener('mousewheel', _this.handleMousewheelScroll, false)

      _this.phoneSliderSwiper = new Swiper('.swiper-menu-power', {
        speed: 1000,
        autoplay: false,
        on: {
          slideChangeTransitionEnd: function () {
            if (this.activeIndex === 3) {
              _this.$refs['follow-text'].classList.remove('show')
            } else {
              _this.$refs['follow-text'].classList.add('show')
            }
          },
        },
      })
    }
  },
  beforeDestroy() {
    if (this.$store.state.isDesktop) {
      this.$refs['wrap'].removeEventListener('mousewheel', this.handleMousewheelScroll, false)
    }
  },
  methods: {
    toggle(index) {
      this.MenuPowerMobile[index].isOpen = !this.MenuPowerMobile[index].isOpen
    },
    openDownload() {
      this.MenuPowerMobile[0].isOpen = true
    },
    handleContactClick() {
      this.$emit('handleContactClick')
    },
    handleMousewheelScroll(e) {
      const _this = this
      if (_this.scrollActive) return false
      if (e.wheelDelta > 60) {
        if (_this.phoneSliderSwiper.activeIndex === 0) {
          _this.$emit('isPhoneSliderSwiperFirst', true)
          _this.scrolling()
        } else {
          _this.phoneSliderSwiper.slidePrev()
          _this.$emit('isPhoneSliderSwiperFirst', false)
          _this.scrolling()
        }
      } else if (e.wheelDelta < -60) {
        if (_this.phoneSliderSwiper.activeIndex === _this.phoneSliderSwiper.slides.length - 1) {
          _this.$emit('isPhoneSliderSwiperLast', true)
          _this.scrolling()
        } else {
          _this.phoneSliderSwiper.slideNext()
          _this.$emit('isPhoneSliderSwiperLast', false)
          _this.scrolling()
        }
      }
    },
    scrolling() {
      const _this = this
      _this.scrollActive = true
      setTimeout(function () {
        _this.scrollActive = false
      }, 1500)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
