<template>
  <WindowSection class="section-2 el">
    <template slot="content">
      <div class="buttons">
        <div class="button">
          <div v-fadein="{ horizontal: true, reserve: true, delay: 400 }"><SvgIcon name="plus" /> No Commission</div>
        </div>
        <div class="button">
          <div v-fadein="{ horizontal: true, reserve: true, delay: 600 }"><SvgIcon name="plus" /> No inflated price</div>
        </div>
        <div class="button">
          <div v-fadein="{ horizontal: true, reserve: true, delay: 800 }"><SvgIcon name="plus" /> No hidden cost</div>
        </div>
      </div>
      <div class="text">
        Elevate the<br v-if="!$store.state.isDesktop" />
        ordering experience<br />
        for your customers<br v-if="!$store.state.isDesktop" />
        with exclusivity.
      </div>
      <div class="buttons">
        <div v-if="$store.state.isDesktop" v-fadein="{ horizontal: true, reserve: true, delay: 1000 }" class="button">
          <div><SvgIcon name="plus" /> Save more without third party commission</div>
        </div>
        <div class="button">
          <div v-fadein="{ horizontal: true, reserve: true, delay: $store.state.isDesktop ? 1200 : 1000 }"><SvgIcon name="plus" /> privacy protected</div>
        </div>
        <div v-if="!$store.state.isDesktop" class="button">
          <div v-fadein="{ horizontal: true, reserve: true, delay: 1200 }"><SvgIcon name="plus" /> no third party commission</div>
        </div>
      </div>
    </template>
  </WindowSection>
</template>

<script>
import WindowSection from '../WindowSection'
export default {
  name: 'SectionSecond',
  components: { WindowSection },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
