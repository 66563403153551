<template>
  <div class="global-header" :style="{ zIndex }">
    <SvgIcon name="logo" @click.native="linkTo('/')" />
    <div class="menu" :class="menuActive ? 'active' : ''">
      <div class="menu-title pointer" @click="handleMenuClick">
        <IconPlusMinus v-if="$store.state.isDesktop" :active="menuActive" />
        <span class="title">MENU</span>
      </div>
      <div v-if="$store.state.isDesktop" class="link-list">
        <span @click="linkTo('/')">HOME</span>
        <span @click="linkTo('/about-us')">ABOUT US</span>
        <span @click="linkTo('/menu-intellgence')">MENU INTELLIGENCE</span>
      </div>
      <div v-else>
        <div class="drawer-menu">
          <SvgIcon name="close" class="pointer" @click.native="handleCloseClick" />
          <ul>
            <li>
              <span @click="linkTo('/')">
                <span>HOME</span>
                <IconArrowRight :mobile-width="318" />
              </span>
            </li>
            <li>
              <span @click="linkTo('/about-us')">
                <span>ABOUT US</span>
                <IconArrowRight :mobile-width="250" />
              </span>
            </li>
            <li>
              <span @click="linkTo('/menu-intellgence')">
                <span>DIGITAL MENU</span>
                <IconArrowRight :mobile-width="178" />
              </span>
            </li>
            <!-- <li>
              <a href="javascript:void(0)" @click="linkTo('/')">GALLERY</a>
              <IconArrowRight :mobile-width="269" />
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlusMinus from '@/components/IconPlusMinus'
import IconArrowRight from '@/components/IconArrowRight'
export default {
  name: 'GlobalHeader',
  components: { IconPlusMinus, IconArrowRight },
  data() {
    return {
      menuActive: false,
      zIndex: 9,
    }
  },
  methods: {
    linkTo(link) {
      this.$router.push(link)
      this.handleCloseClick()
      if (!this.$store.state.isDesktop) window.scrollTo(0, 0)
    },
    handleMenuClick() {
      this.menuActive = !this.menuActive
      if (!this.$store.state.isDesktop) this.zIndex = 99
    },
    handleCloseClick() {
      const _this = this
      _this.menuActive = false
      setTimeout(function () {
        _this.zIndex = 9
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
