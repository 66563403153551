<template>
  <WindowSection class="section-6 el">
    <template slot="content">
      <div v-fadein="{ vertical: true }" class="title">why zomi?</div>
      <div v-if="$store.state.isDesktop" class="text">
        It’s easy to find a technical solution for online ordering, as many companies provide it.<br />
        However, the key to unlock a prosperous direct ordering hinges on the accumulation of customer<br />
        resources and interactions. With ZOMI, achieving this is a breeze ~
      </div>
      <div v-else v-fadein="{ vertical: true }" class="text">
        It’s easy to find a technical solution<br />
        for online ordering, as many<br />
        companies provide it. However, the<br />
        key to building a successful direct<br />
        channel is to accumulate valuable<br />
        customer resources and utilize<br />
        them effectively.
      </div>
      <BounceOff />
    </template>
  </WindowSection>
</template>

<script>
import WindowSection from '../WindowSection'
import BounceOff from '../BounceOff'
export default {
  name: 'SectionSixth',
  components: { WindowSection, BounceOff },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
