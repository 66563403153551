<template>
  <div class="global-footer">
    <div v-if="$store.state.isDesktop" class="footer-desktop">
      <!-- footer-left -->
      <ul class="footer-left">
        <li class="call-us">
          <IconArrowRight :desktop-width="118" />
          <a href="tel:7783174160"><span>CALL US AT: 778-317-4160</span></a>
        </li>
        <li class="email">
          <IconArrowRight :desktop-width="195" />
          <a href="mailto:HELLO@ZOMI.MENU"><span>HELLO@ZOMI.MENU</span></a>
        </li>
        <li class="find-us">
          <span>FIND US:</span>
          <div>
            450 SW Marine Dr Floor 18<br />
            VANCOUVER, BC<br />
            V5X 0C3
          </div>
        </li>
      </ul>
      <!-- footer-center -->
      <div class="footer-center">
        <div class="zomi-ins">
          <SvgIcon name="zomi-footer" />
          <a href="https://www.instagram.com/zomi_experience/" target="_blank">
            <SvgIcon name="ins" />
          </a>
        </div>
        <div class="hours">
          <div>
            Monday to Friday:<br />
            9:00 a.m. to 9:00 p.m. (PT)
          </div>
          <SvgIcon name="office-hours" />
        </div>
      </div>
      <!-- footer-right -->
      <div class="footer-right">
        <div>
          <div class="qrcode-title">
            <div>
              <div>SCAN FOR</div>
              <div>WHATSAPP</div>
            </div>
            <SvgIcon name="phone" />
          </div>
          <a href="https://wa.me/7788958661"><img src="@/assets/images/QRCode.jpeg" draggable="false" /></a>
        </div>
      </div>
    </div>

    <div v-else class="footer-mobile">
      <div class="zomi-ins">
        <SvgIcon name="zomi-footer" />
        <a href="https://www.instagram.com/zomi_experience/" target="_blank">
          <SvgIcon name="ins" />
        </a>
      </div>
      <div class="find-us">
        <div>
          FIND US AT:<br />
          450 SW Marine Dr Floor 18<br />
          VANCOUVER, BC<br />
          V5X 0C3
        </div>
        <SvgIcon name="face-1" />
      </div>
      <div class="email">
        <IconArrowRight :mobile-width="230" />
        <span>HELLO@ZOMI.MENU</span>
      </div>
      <div class="call-us">
        <IconArrowRight :mobile-width="140" />
        <a href="tel:7783174160"><span>CALL US AT: 778-317-4160</span></a>
      </div>
      <div class="qrcode-and-hours">
        <div class="qrcode">
          <div>
            <div>
              <div>
                SCAN FOR<br />
                WHATSAPP
              </div>
              <SvgIcon name="phone" />
            </div>
            <a href="https://wa.me/7788958661"><img src="@/assets/images/QRCode.jpeg" draggable="false" /></a>
          </div>
        </div>
        <div class="hours">
          <SvgIcon name="office-hours-mobile" />
          <div>
            Monday to Friday:<br />
            9:00 a.m. to 9:00 p.m. (PT)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowRight from '@/components/IconArrowRight'
export default {
  name: 'GlobalFooter',
  components: { IconArrowRight },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
