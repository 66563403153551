<template>
  <div class="about-view">
    <div v-if="$store.state.isDesktop" class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-home">
          <VideoBanner />
          <!-- <ImageBanner /> -->
        </div>
        <div ref="scroll-container" class="swiper-slide scroll-container">
          <TextSlider ref="anime" />
          <div class="main-container">
            <div class="svg-wrap-container">
              <SvgWrap />
              <div class="content">
                <FlyPhone1 />
                <FlyPhone2 />
              </div>
            </div>
            <Testimonial />
            <GlobalFooter />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <VideoBanner />
      <TextSlider ref="anime" />
      <div class="main-container">
        <div class="svg-wrap-container">
          <SvgWrap />
          <div class="content">
            <FlyPhone1 />
            <FlyPhone2 />
          </div>
        </div>
        <Testimonial />
        <GlobalFooter />
      </div>
    </div>
  </div>
</template>

<script>
// 插件
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

// 组件
import VideoBanner from './components/VideoBanner'
import TextSlider from './components/TextSlider'
import FlyPhone1 from './components/FlyPhone1'
import FlyPhone2 from './components/FlyPhone2'
import Testimonial from './components/Testimonial'
import SvgWrap from './components/SvgWrap'

// 全局组件
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'AboutView',
  components: { VideoBanner, TextSlider, FlyPhone1, FlyPhone2, Testimonial, SvgWrap, GlobalFooter },
  data() {
    return {
      scrollActive: false,
      mainSwiper: null,
      freeSwiper: null,
      swiperTop: 0,
      touchStartY: null,
      touchEndY: null,
      ellipseAnime: null,
    }
  },
  mounted() {
    if (this.$store.state.isDesktop) this.init()
    document.querySelector('.svg-wrap-container').style.height = document.querySelector('.svg-wrap-container .content').offsetHeight + 'px'
  },
  beforeDestroy() {
    // 取消监听滚轮
    if (this.$store.state.isDesktop) {
      window.removeEventListener('mousewheel', this.handleMousewheelScroll, false)
      window.removeEventListener('touchstart', this.handleTouchStart, false)
      window.removeEventListener('touchmove', this.handleTouchMove, false)
      window.removeEventListener('touchend', this.handleTouchEnd, false)
    }
  },
  methods: {
    init() {
      const _this = this

      // 监听滚轮，第二页回翻
      window.addEventListener('mousewheel', _this.handleMousewheelScroll, false)

      // 监听touch事件
      window.addEventListener('touchstart', _this.handleTouchStart, false)
      window.addEventListener('touchmove', _this.handleTouchMove, false)
      window.addEventListener('touchend', _this.handleTouchEnd, false)

      _this.initAnime()
      // Swiper
      _this.mainSwiper = new Swiper('.swiper-container', {
        speed: 1000,
        direction: 'vertical',
        allowTouchMove: false,
      })
    },
    // 滚轮事件
    handleMousewheelScroll(e) {
      const _this = this
      if (_this.scrollActive) return false
      const scrollTop = _this.$refs['scroll-container'].scrollTop
      // 如果在第一页
      if (e.wheelDelta < -60 && !_this.mainSwiper.activeIndex) {
        if (_this.$store.state.isDesktop) {
          if (_this.ellipseAnime.direction === 'reverse') _this.ellipseAnime.reverse()
          _this.ellipseAnime.play()
        }
        _this.mainSwiper.slideNext()
        _this.scrolling()
      }
      // 如果滚轮是向上，并且在第二页顶部
      if (e.wheelDelta > 60 && !scrollTop) {
        if (_this.$store.state.isDesktop) {
          if (_this.ellipseAnime.direction === 'normal') _this.ellipseAnime.reverse()
          _this.ellipseAnime.play()
          setTimeout(function () {
            _this.mainSwiper.slidePrev()
            _this.scrolling()
          }, (_this.ellipseAnime.duration / 3) * 2)
        } else {
          _this.mainSwiper.slidePrev()
          _this.scrolling()
        }
      }
    },
    scrolling() {
      const _this = this
      _this.scrollActive = true
      setTimeout(function () {
        _this.scrollActive = false
      }, 1500)
    },
    // touch事件
    handleTouchStart(e) {
      this.touchStartY = e.touches[0].pageY
      this.touchEndY = null
    },
    handleTouchMove(e) {
      this.touchEndY = e.touches[0].pageY
    },
    handleTouchEnd() {
      let scrollTop = this.$refs['scroll-container'].scrollTop
      if (!this.touchEndY || !this.touchStartY) return false
      if (!this.mainSwiper.activeIndex && this.touchEndY < this.touchStartY) {
        this.mainSwiper.slideNext()
      }
      if (this.mainSwiper.activeIndex && this.touchEndY > this.touchStartY && !scrollTop) {
        this.mainSwiper.slidePrev()
      }
    },
    initAnime() {
      this.ellipseAnime = this.$anime({
        targets: this.$refs['anime'].$el,
        clipPath: 'ellipse(150% 100% at 50% 80%)',
        easing: 'easeInQuad',
        duration: 1000,
        autoplay: false,
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
