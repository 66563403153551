<template>
  <div class="section price-section">
    <div v-fadein="{ vertical: true }" class="card">
      <div class="header">PRICE</div>
      <div class="content">
        <div class="price">FROM $99/MONTH</div>
        <div class="desc">Own your online relationship with your customers with no commissions.</div>
        <div class="features">
          <div class="line">
            <SvgIcon name="checked" />
            <p v-if="$store.state.isDesktop">0% Commission Delivery & Pick-up</p>
            <p v-else>Easy online ordering</p>
          </div>
          <div class="line">
            <SvgIcon name="checked" />
            <p v-if="$store.state.isDesktop">Branded digital menus</p>
            <p v-else>Branded digital menus</p>
          </div>
          <div class="line">
            <SvgIcon name="checked" />
            <p v-if="$store.state.isDesktop">QR code signage</p>
            <p v-else>QR code signage</p>
          </div>
        </div>
        <button>
          <IconArrowRight :desktop-width="53" :mobile-width="68" />
          <span @click="jump">Get Started</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowRight from '@/components/IconArrowRight'
export default {
  name: 'PriceSection',
  components: { IconArrowRight },
  methods: {
    jump() {
      this.$router.push({
        name: 'contact-us',
      })
      if (!this.$store.state.isDesktop) window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
