<template>
  <div class="bounce-off">
    <SvgIcon name="face-1" />
  </div>
</template>

<script>
export default {
  name: 'BounceOff',
  data() {
    return {
      timer: null,
      pause: false,
    }
  },
  mounted() {
    const _this = this
    _this.sporting(document.querySelector('.bounce-off'))
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        // 离开当前tab标签
        _this.pause = true
      } else {
        _this.pause = false
      }
    })
  },
  methods: {
    sporting(now) {
      const _this = this
      let x = now.offsetLeft,
        y = now.offsetTop
      let w = 0,
        h = 0
      let flagX = true
      let flagY = true
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        if (_this.pause) return false
        w = window.innerWidth - now.offsetWidth
        h = (_this.$store.state.isDesktop ? 540 : window.innerHeight) - now.offsetHeight
        if (x > w) flagX = false
        if (y > h) flagY = false
        if (x < 1) flagX = true
        if (y < 1) flagY = true
        x = flagX ? x + 6 : x - 6
        y = flagY ? y + 6 : y - 6
        now.style.left = `${x}px`
        now.style.top = `${y}px`
      }, 80)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
