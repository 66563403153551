<template>
  <div class="svg-wrap">
    <div class="bounce-off">
      <SvgIcon :name="'face-' + active" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SvgWrap',
  data() {
    return {
      active: 1,
      timer: null,
      pause: false,
    }
  },
  mounted() {
    const _this = this
    _this.sporting(document.querySelector('.bounce-off'))
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        // 离开当前tab标签
        _this.pause = true
      } else {
        _this.pause = false
      }
    })
  },
  methods: {
    sporting(now) {
      const _this = this
      let x = now.offsetLeft,
        y = now.offsetTop
      let w = 0,
        h = 0
      let flagX = true
      let flagY = true
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        if (_this.pause) return false
        w = window.innerWidth - now.offsetWidth
        h = window.innerHeight - now.offsetHeight
        if (x > w) {
          flagX = false
          _this.generateNewActive()
        }
        if (y > h) {
          flagY = false
          _this.generateNewActive()
        }
        if (x < 1) {
          flagX = true
          _this.generateNewActive()
        }
        if (y < 1) {
          flagY = true
          _this.generateNewActive()
        }
        x = flagX ? x + 6 : x - 6
        y = flagY ? y + 6 : y - 6
        now.style.left = `${x}px`
        now.style.top = `${y}px`
      }, 80)
    },
    updateActive(newActive) {
      const _this = this
      _this.active = newActive
    },
    generateNewActive() {
      const _this = this
      let newActive = _this.randomNum(1, 4)
      _this.ifRepeat(newActive)
    },
    ifRepeat(newActive) {
      const _this = this
      if (_this.active === newActive) {
        _this.generateNewActive()
      } else {
        _this.updateActive(newActive)
      }
    },
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1)
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum)
        default:
          return 0
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
