<template>
  <div ref="faq-form" class="faq-form">
    <div class="faq-box">
      <div class="title">FAQ</div>
      <div v-for="(item, index) in faqList" :key="index" class="faq">
        <div class="question" @click="handleQuestionClick(index)">{{ item.question }}</div>
        <transition name="question">
          <div v-show="item.active" class="answer">
            <div>{{ item.answer }}</div>
          </div>
        </transition>
      </div>
    </div>

    <div class="form-box">
      <!-- If you are a restaurant owner or manager, get in touch with us. -->
      <div class="title">IF YOU ARE A RESTAURANT OWNER<br />OR MANAGER, GET IN TOUCH WITH US.</div>
      <!-- <div class="title">GET IN TOUCH WITH<br />OUR CONSULTANTS.</div> -->
      <div class="sub-title">We will contact you back within 24 hours.</div>
      <form action="https://formspree.io/f/xayvvzda" method="POST" class="input-form">
        <div class="input-name">
          <input type="text" name="First Name" placeholder="First name" required="required" />
          <input type="text" name="Last Name" placeholder="Last name" required="required" />
        </div>
        <div class="input-business-name">
          <input type="text" name="Business name" placeholder="Business name" required="required" />
        </div>
        <div class="input-email">
          <input type="text" name="Email Address" placeholder="Email" required="required" />
        </div>
        <div class="message-select">
          <ul v-show="$store.state.isDesktop && selectActive" class="options">
            <li v-for="(option, index) in optionList" :key="index" @click="handleOptionClick">{{ option }}</li>
          </ul>
          <SvgIcon name="arrow-down" />
          <span v-if="!selectValue" class="select-placeholder">How can we assist you?</span>
          <select v-model="selectValue" name="How can we assist you?" required="required" @click="handleSelectClick">
            <option v-for="(option, index) in optionList" v-show="!$store.state.isDesktop" :key="index" :value="option">{{ option }}</option>
          </select>
        </div>
        <div v-if="showTextarea" class="textarea-message">
          <textarea name="Write your message" placeholder="Write your message" cols="30" rows="6" required="required" />
        </div>
        <div class="button-submit">
          <button type="submit">SEND MESSAGE</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqForm',
  data() {
    return {
      faqList: [
        {
          question: 'Q1. How to sign up for MENU Intelligence?',
          answer:
            'There are two ways of signing up: you can download the app and create the menu by yourself or get in touch with our consultants here. In this last case, a ZOMI expert will contact you shortly, in order to start the onboarding process. This specialist will guide you every step of the way. After this first part is finished, you can manage your menu with our smart editor.',
          active: false,
        },
        {
          question: 'Q2. Is the app intuitive?',
          answer:
            'Yes, MENU Intelligence is effective and easy to use. With only a few clicks, you can submit or edit titles, descriptions, prices, photos, and so on.',
          active: false,
        },
        {
          question: 'Q3. Why is MENU Intelligence different from other digital menus?',
          answer:
            'MENU Intelligence is much more than a digital menu. Besides having this function, the app helps you to actively connect with your clients, turning them into followers of your brand. As a result, you get to know relevant data regarding these visitors.',
          active: false,
        },
        {
          question: 'Q4. How do clients are converted into followers?',
          answer:
            'Once your clients subscribe to your restaurant within the app, they automatically become your followers. After their subscription, you can reach them anytime, anywhere, with several tools and for different purposes.',
          active: false,
        },
        {
          question: 'Q5. Why MENU Intelligence is better than paper menus?',
          answer:
            'With MENU Intelligence, you save time and money, since you can easily change or update information in your menu. Plus, different features aid you to create accurate marketing campaigns, based on the behavior of your followers. Using the app is modern, safe, and simple.',
          active: false,
        },
        {
          question: 'Q6. Is it possible to tailor the digital menu according to the restaurant?',
          answer:
            'Yes, it is. There are many options for customization in the app, and every menu is created according to the preferences of the business. This is why each one of them becomes unique.',
          active: false,
        },
        {
          question: 'Q7. How does MENU Intelligence improve the repurchase rate of a restaurant?',
          answer:
            'MENU Intelligence provides a seamless customer experience, utilizing the power of the “new normal”. When your customers subscribe to your business, you collect part of their data and analyze their behavior. Consequently, the app enables you to develop better deals and improve the experience of your clients.',
          active: false,
        },
        {
          question: 'Q8. What are the main features of the app?',
          answer:
            'Currently, the app offers a subscription section, a menu editor, a QR code/NFC reader, and tools for engagement (welcome offer/loyalty program), among other features. New functionalities will be launched soon, such as email/SMS activation and a feedback section.',
          active: false,
        },
      ],
      optionList: ['Inquiries about the onboarding package', 'Questions about the menu', 'Other inquiries'],
      selectActive: false,
      selectValue: '',
      showTextarea: false,
    }
  },
  watch: {
    selectValue() {
      if (this.selectValue === 'Inquiries about the onboarding package') {
        this.showTextarea = false
      } else {
        this.showTextarea = true
      }
    },
  },
  methods: {
    handleOptionClick(e) {
      this.selectValue = e.target.innerText
      this.selectActive = false
    },
    handleSelectClick() {
      if (this.$store.state.isDesktop) {
        this.selectActive = !this.selectActive
      }
    },
    handleQuestionClick(index) {
      const currentActive = this.faqList[index].active
      this.faqList.forEach((item) => {
        item.active = false
      })
      this.faqList[index].active = !currentActive
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
