<template>
  <div class="testimonial">
    <div v-if="$store.state.isDesktop" class="testimonial-wrap">
      <div class="progress-animate">
        <div class="circle first"><em /></div>
        <div class="circle last"><em /></div>
        <div class="line"><em /></div>
        <div class="text">
          <span>meet our top executives</span>
        </div>
      </div>
      <div ref="scroller">
        <ul ref="scroll-wrapper" class="scroll">
          <li class="item-wrap" />
          <li v-for="(item, index) in silderData" :key="index" class="item-wrap">
            <img v-if="!item.isOpen" :src="item.imgSrc" />
            <img v-else :src="item.imgSrcActive" />
            <div class="job">{{ item.job }}</div>
            <div class="name">{{ item.name }}</div>
            <div class="collapse pointer" @click="toggleContent(index)">
              <IconPlusMinus :active="item.isOpen" />
            </div>
            <transition name="display">
              <div v-if="item.isOpen" class="evaluate">
                <div>{{ item.evaluate }}</div>
              </div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="testimonial-wrap">
      <div class="meet-btn">meet our top executives</div>
      <ul>
        <li v-for="(item, index) in silderData" :key="index" class="item-wrap swiper-slide swiper-slide1">
          <img v-if="!item.isOpen" :src="item.imgSrc" />
          <img v-else :src="item.imgSrcActive" />
          <div class="job">{{ item.job }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="collapse" @click="toggleContent(index)">
            <IconPlusMinus :active="item.isOpen" />
          </div>

          <!-- <div v-if="item.isOpen" class="collapse open">
            <SvgIcon name="plus" />
          </div>
          <div v-else class="collapse close" @click="toggleContent(index)">
            <SvgIcon name="minus" />
          </div> -->
          <transition name="display">
            <div v-if="item.isOpen" class="evaluate">
              <div>{{ item.evaluate }}</div>
            </div>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconPlusMinus from '@/components/IconPlusMinus'
// 插件
import BScroll from '@better-scroll/core'
import MouseWheel from '@better-scroll/mouse-wheel'
BScroll.use(MouseWheel)

export default {
  name: 'TestImonial',
  components: { IconPlusMinus },
  data() {
    return {
      silderData: [
        {
          imgSrc: require('@/assets/images/executives/Richard.png'),
          imgSrcActive: require('@/assets/images/executives/Richard-active.png'),
          job: 'CEO',
          name: 'Richard Huang',
          evaluate: `“It is ZOMI’s mission to digitalize every dish and provide customers with personalized food experiences.”`,
          isOpen: false,
        },
        {
          imgSrc: require('@/assets/images/executives/Toddy.png'),
          imgSrcActive: require('@/assets/images/executives/Toddy-active.png'),
          job: 'CAIO',
          name: 'Toddy Mao',
          evaluate: `“We are messengers, delivering the future of food discovery to people while leveraging AI to revolutionize the food industry.”`,
          isOpen: false,
        },
        {
          imgSrc: require('@/assets/images/executives/Harvey.png'),
          imgSrcActive: require('@/assets/images/executives/Harvey-active.png'),
          job: 'CTO',
          name: 'Harvey Wang',
          evaluate: `“I have been working with the power of algorithms for 18 years. At ZOMI, my knowledge and enthusiasm help each restaurant to grow and many consumers to fulfill their taste preferences.”`,
          isOpen: false,
        },
      ],
      scroll: null,
      swiper: null,
      animeBox: null,
      animeFirstCircle: null,
      animeLastCircle: null,
      animeLine: null,
      animeText: null,
    }
  },
  mounted() {
    if (!this.$store.state.isDesktop) return false
    this.initBscroll()
    this.initAnime()
  },
  methods: {
    initBscroll() {
      const _this = this
      _this.scroll = new BScroll(_this.$refs['scroller'], {
        scrollX: true,
        scrollY: false,
        mouseWheel: true,
        bounce: false,
        probeType: 3,
      })
      const scrollWidth = _this.$refs['scroll-wrapper'].clientWidth
      const windowWidth = _this.$refs['scroller'].clientWidth
      _this.scroll.on('scroll', (position) => {
        const progress = Math.abs(position.x) / (scrollWidth - windowWidth)
        _this.animeBox.seek(_this.animeBox.duration * progress)
        _this.animeText.seek(_this.animeText.duration * progress)
        _this.animeLine.seek(_this.animeLine.duration * progress)
        _this.animeFirstCircle.seek(_this.animeFirstCircle.duration * progress)
        _this.animeLastCircle.seek(_this.animeLastCircle.duration * progress)
      })
      _this.scroll.on('mousewheelMove', (position) => {
        const progress = Math.abs(position.x) / (scrollWidth - windowWidth)
        const container = document.querySelector('.scroll-container')
        const scrollTop = container.scrollTop
        if (progress === 1) {
          container.scrollTo(0, scrollTop + 10)
        }
        if (!progress) {
          container.scrollTo(0, scrollTop - 10)
        }
      })
    },
    initAnime() {
      this.animeBox = this.$anime({
        targets: '.progress-animate',
        keyframes: [
          {
            left: '75vw',
            top: '22vw',
            duration: 2000,
          },
          {
            left: '75vw',
            top: '22vw',
            duration: 1000,
          },
        ],
        duration: 3000,
        easing: 'linear',
        autoplay: false,
      })
      this.animeFirstCircle = this.$anime({
        targets: '.circle.first em',
        keyframes: [
          {
            width: '12.8125vw',
            height: '12.8125vw',
            duration: 2000,
          },
          {
            opacity: 0.6,
            duration: 1000,
          },
        ],
        easing: 'linear',
        autoplay: false,
      })
      this.animeLastCircle = this.$anime({
        targets: '.circle.last em',
        keyframes: [
          {
            width: '12.8125vw',
            height: '12.8125vw',
            duration: 2000,
          },
          {
            width: '29.6875vw',
            height: '29.6875vw',
            translateX: '-13vw',
            duration: 1000,
          },
        ],
        easing: 'linear',
        autoplay: false,
      })
      this.animeLine = this.$anime({
        targets: '.progress-animate .line em',
        keyframes: [
          {
            width: '65vw',
            delay: 2000,
            duration: 1000,
          },
        ],
        duration: 3000,
        easing: 'linear',
        autoplay: false,
      })
      this.animeText = this.$anime({
        targets: '.progress-animate .text span',
        keyframes: [
          {
            rotate: '360deg',
            scale: 0.82,
            opacity: 1,
            duration: 1000,
          },
          {
            rotate: '720deg',
            scale: 0.63,
            opacity: 0,
            duration: 1000,
          },
          {
            rotate: '1080deg',
            scale: 0.45,
            opacity: 0,
            duration: 1000,
          },
        ],
        duration: 3000,
        easing: 'linear',
        autoplay: false,
      })
    },
    toggleContent(index) {
      this.silderData[index].isOpen = !this.silderData[index].isOpen
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
