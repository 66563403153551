<template>
  <div class="icon-arrow-right" :style="{ '--desktop-width': `${desktopWidth / 19.2}vw`, '--mobile-width': `${mobileWidth / 7.5}vw` }">
    <div class="line" :style="{ backgroundColor: color }" />
    <SvgIcon name="arrow-right" :style="{ fill: color }" />
  </div>
</template>

<script>
export default {
  name: 'IconArrowRight',
  props: {
    desktopWidth: {
      type: Number,
      default: 22,
    },
    mobileWidth: {
      type: Number,
      default: 22,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
